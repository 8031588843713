/*=========================================================================================
  File Name: moduleCountryMutations.js
  Description: Country Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    debugger;
    state.propertiess.unshift(item)
  },
  SET_properties(state, propertiess) {
    debugger;
    try {

      state.propertiess = propertiess;

    } catch (error) {
      console.error("Failed to parse properties data JSON:", error);
      state.propertiess = [];
    }
  },
  UPDATE_properties(state, properties) {
    debugger
    const propertiesIndex = state.propertiess.findIndex((p) => p.Id == properties.Id)
    Object.assign(state.propertiess[propertiesIndex], properties)
  },
  REMOVE_ITEM(state, itemId) {
      const ItemIndex = state.propertiess.findIndex((p) => p.ID == itemId)
      state.propertiess.splice(ItemIndex, 1)
  },
}
