/*=========================================================================================
  File Name: moduleCountryActions.js
  Description: Country Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  addItem({ commit }, item) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    debugger;
    return new Promise((resolve, reject) => {
      axios.post("api/Properities/Create", item,{headers:Token})
        .then((response) => {debugger;
          commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchDataListItems({ commit }) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.get("api/Properities/GetAll",{headers:Token})
        .then((response) => {
        
          commit('SET_properties', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  updateItem({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios.post("api/Properities/Update", item)
        .then((response) => {
          commit('UPDATE_properties', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetItemByID({ commit }, itemid) {
    return new Promise((resolve, reject) => {
      axios.get("api/Properities/GetById?Id=" + itemid)
        .then((response) => {
          commit('UPDATE_properties', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  removeItem({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios.delete(`api/Properities/Delete/${encodeURIComponent(item.ID)}`)
        .then((response) => {
          commit('REMOVE_ITEM', item.ID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  
    UploadNewImage(context, file) {
      debugger;
      const formData = new FormData();
      formData.append("file",file, file.name);
      return new Promise((resolve, reject) => {
        axios
          .post("/API/Common/UploadImage", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(response => {
            if (response.status == 200) {
              resolve(response);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    }
}
