/*=========================================================================================
  File Name: moduleCountryMutations.js
  Description: Country Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.AdsCatgeorys.unshift(item)
  },
  SET_AdsCatgeorys(state, _AdsCatgeorys) {
    debugger;
    try {
 
      state.AdsCatgeorys = _AdsCatgeorys;
     
    } catch (error) {
      console.error("Failed to parse AdsCatgeorys data JSON:", error);
      state.AdsCatgeorys = [];
    }
  },
  UPDATE_AdsCatgeory(state, AdsCatgeory) {
    debugger
    const AdsCatgeoryIndex = state.AdsCatgeorys.findIndex((p) => p.Id == AdsCatgeory.Id)
    Object.assign(state.AdsCatgeorys[AdsCatgeoryIndex], AdsCatgeory)
  },
  REMOVE_ITEM(state, itemId) {
      const ItemIndex = state.AdsCatgeorys.findIndex((p) => p.ID == itemId)
      state.AdsCatgeorys.splice(ItemIndex, 1)
  },
}
